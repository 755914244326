.sideMenu {
  z-index: 1;
}

.contentful p,
.contentful span,
.contentful a,
.contentful li {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.5;
}

.contentful a {
  color: #1677ff;
  text-decoration: none;
}

.contentful a:hover {
  color: #69b1ff;
}

.contentful table {
  font-size: 14px;
  width: 80%;
}

.contentful table th {
  font-weight: bold;
  text-align: left;
  padding: 8px;
}

.contentful table td p {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
}

.contentful table tr th p {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
}

.contentful table td {
  padding: 8px;
}

.sider {
  z-index: 10;
}

/* Small Screens (600px and below) - Mobile devices */
@media (max-width: 768px) {
  .slider {
    display: none;
  }
}
