.contactUs {
  max-width: 1500px;
  margin: 0 auto;
  min-height: 50vh;
}

.contactUsContainer {
  padding: 20px;
  background-color: #fafafa;
  max-width: 1200px;
  margin: 0 auto;
}

.contactInfoSection {
  background-color: #f4f4f4;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.contactInfoSection .ant-typography {
  font-size: 1rem;
  color: #333;
  margin: 8px 0;
}

.contactInfoSection a {
  color: #06c;
  text-decoration: none;
}

.contactInfoSection a:hover {
  text-decoration: underline;
}

.contactGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  text-align: left;
  margin: 0 auto;
}

.contactCard {
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.contactCard:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}

.contactCardTitle {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 8px;
  color: #333;
}

.contactCardSubtitle {
  font-size: 1rem;
  margin-bottom: 8px;
  font-weight: 500;
}

.contactCard p {
  margin: 0.5rem 0;
}

@media (max-width: 1024px) {
  .contactGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 800px) {
  .contactGrid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 2160px) {
  .contactUs {
    min-height: 65vh;
  }
}
