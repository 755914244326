.headerContainer {
  display: flex;
  align-items: center;

  /* padding: 0 20px; */
  height: 60px;
  background-color: #fff;
}

.logo {
  height: 40px;
  margin-right: 30px;
}

.menu {
  flex-grow: 1;
  display: flex;
  min-width: 0;
}

.loginBtn {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.logoutBtnAvatar {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-left: auto;
}
