.headerContainer {
  width: 100%;
  background-color: #fff;
}

.mainContainer {
  margin: 0 192px;
  padding: 0 24px;
}

.logoContainer {
  margin: 16px 0;
  height: 70px;
}

.logo {
  width: 288px;
}

.header {
  border-top: 3px solid rgb(0, 163, 224);
  height: 67px;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px -1px, rgba(0, 0, 0, 0.14) 0 4px 5px 0, rgba(0, 0, 0, 0.12) 0 1px 10px 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  margin-left: 210px;
}

.authButton {
  margin-right: 50px;
}
