/* Footer container styling */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bfc2c4;
  padding: 2.5rem 6.25rem;
}

/* Footer grid layout for different sections */
.footerGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  text-align: left;
  max-width: 100rem;
  width: 100%;
}

.textElement {
  margin-top: 2.5em;
  display: flex;
  flex-direction: column;
  max-width: 15.5rem;
}

.logContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.9rem;
}

.sciLogo,
.logo {
  filter: brightness(0) invert(1);
  object-fit: contain;
  transition: transform 0.3s ease;
}

.sciLogo {
  height: 6em;
}

.logo {
  height: 6em;
  width: auto;
  transition: transform 0.3s ease;
}

.logoRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logo:hover,
.sciLogo:hover {
  filter: none;
  transition: filter 0.3s ease;
}

/* Small Screens (600px and below) - Mobile devices */
@media (max-width: 600px) {
  .logoRow {
    flex-direction: column;
  }
}

/* Small Screens (800px and below) - Mobile devices and small tablets */
@media (max-width: 800px) {
  .footer {
    padding: 100px 0 20px 0;
  }

  .footerGrid {
    grid-template-columns: 1fr;
  }

  .textElement {
    max-width: 90%;
    margin: 0 auto;
  }

  .logoRow {
    align-items: center;
    gap: 50px;
    margin-bottom: 3rem;
  }

  .sciLogo {
    height: 4em;
    margin: 20px 0;
  }

  .logo {
    height: 5em;
  }

  .logo:hover {
    transform: scale(1.05);
  }
}

/* Medium Screens (601px - 1024px) - Tablets and small laptops */
@media (min-width: 601px) and (max-width: 1024px) {
  .footerGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .logo {
    height: 4em;
  }
}

/* Large Screens (1025px and above) - Desktops and large monitors */
@media (min-width: 1025px) {
  .logo {
    height: 6em;
  }
}
